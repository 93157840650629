.tile {
  width: 500px;
  height: 450px;
  margin: 1.5rem;
  background-color: #ccc;
  background-size: cover;
  position: relative;
  cursor: pointer;
  transition: all 0.4s ease-out;
  box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.44);
  overflow: hidden;
  color: white;
}
.tile img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: all 0.4s ease-out;
}
.tile .text {
  position: absolute;
  padding: 30px;
  height: calc(100% - 60px);
}
.tile h1 {
  font-weight: 500;
  margin: 0;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
}
.tile h2 {
  font-weight: 100;
  margin: 20px 0 0 0;
  font-style: italic;
  transform: translateX(200px);
}
.tile p {
  font-weight: 300;
  margin: 20px 0 0 0;
  line-height: 25px;
  transform: translateX(-200px);
  transition-delay: 0.2s;
}

.animate-text {
  opacity: 0;
  transition: all 0.6s ease-in-out;
}
.tile:hover {
  /*   background-color:#99aeff; */
  box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.64);
  transform: scale(1.05);
}
.tile:hover img {
  opacity: 0.2;
}
.tile:hover .animate-text {
  transform: translateX(0);
  opacity: 1;
}

.tile:hover span {
  opacity: 1;
  transform: translateY(0px);
}

@media (max-width: 800px) {
  .wrap {
    flex-direction: column;
    margin-top: 10%;
  }

  .tile {
    width: 350px;
    height: 500px;
    margin-bottom: 30px;
  }
}
