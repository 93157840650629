.name {
  font-size: 6rem;
}

.title {
  font-size: 3rem;
  color: #212529;
}

.p-5 button {
  border: 2px solid rgba(242, 103, 38, 0.8);
}

.p-5 button:hover {
  background: rgba(242, 103, 38, 0.8);
}

.view-projects:hover {
  color: white;
  font-weight: bold;
  
}

@media (max-width: 500px) {
  .name {
    font-size: 3rem;
  }
}
