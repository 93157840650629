.wrapper {
  display: grid;
  margin: 50%;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-gap: 2rem;
  margin: 2rem;
}

* {
  font-family: "Poppins", sans-serif;
}

.overlay {
  background-color: rgba(242, 103, 38, 0.8);
  /* border: 5px solid rgba(242, 103, 38, 0.8); */
  border-radius: 8px;
  height: 400px;
  margin-bottom: 5%;
}

.card {
  height: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);   */
  box-shadow: 1px 5px 5px rgba(242, 103, 38, 0.25);
  display: grid;
  grid-template-rows: 1fr 1fr;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.text-right a {
  color: #eee;
}
@media only screen and (min-width: 1000px) {
  .wrapper {
    grid-template-columns: repeat(auto-fill, minmax(575px, 1fr));
  }
  .card {
    height: 450px;
  }

  .overlay {
    height: 500px;
    margin-bottom: 15%;
  }
}

h2,
.view-site {
  color: #fff;
  font-weight: bold;
  font-style: italic;
  margin: 20px 0 0 20px;
  overflow: hidden;
}

img {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.card:hover {
  /* box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0 15px 20px rgba(242, 103, 38, 0.5); */
  transform: translate(10px, 10px);
}

/* .card:hover img {
  transform: translateY(-10px);
} */
